<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- website -->
        <b-col lg="8">
          <b-form-group label-for="company-name" label="Company Name">
            <b-form-input
              id="company-name"
              v-model="userData.company_name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col lg="8">
          <b-form-group label-for="company-email" label="Company Email">
            <b-form-input
              id="company-email"
              v-model="userData.company_email"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col lg="8">
          <b-form-group
            label-for="company_address_lines1"
            label="Company Address"
          >
            <b-form-input
              id="company_address_lines1"
              v-model="userData.company_address_lines1"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col lg="8" v-if="userData.company_address_lines2">
          <b-form-group
            label-for="company_address_lines2"
            label="Company Address1"
          >
            <b-form-input
              id="company_address_lines2"
              v-model="userData.company_address_lines2"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col lg="8" v-if="userData.company_address_lines3">
          <b-form-group
            label-for="company_address_lines3"
            label="Company Address2"
          >
            <b-form-input
              id="company_address_lines3"
              v-model="userData.company_address_lines3"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col lg="8" v-if="userData.company_website">
          <b-form-group label-for="company-website" label="Company Website">
            <b-form-input
              id="company-website"
              v-model="userData.company_website"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import organizationStoreModule from "../../apps/organization/organizationStoreModule";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
  },

  setup(props, { emit }) {
    let isLoading = ref(false);
    const userData = JSON.parse(localStorage.getItem("userData"));
    let gcpBillingAccountIdData = ref([]);
    let gcpBillingAccountId = ref("");

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";

    const toast = useToast();

    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });

    const yourMethod = (value) => {
      gcpBillingAccountId.value = "";
      gcpBillingAccountIdData.value.push(value);
    };

    const deleteGcpBillingAccountId = (gcpBillingAccountId) => {
      for (const [key, value] of Object.entries(
        gcpBillingAccountIdData.value
      )) {
        if (value === gcpBillingAccountId) {
          gcpBillingAccountIdData.value.splice(key, 1);
        }
      }
    };

    const onSubmit = () => {
      isLoading.value = true;
      const organizationData = {
        id: userData.company_id,
        name: userData.company_name,
        website: userData.company_website,
        email: userData.company_email,
        address: userData.company_address,
      };

      store
        .dispatch("app-organization/updateOrganization", organizationData)
        .then((res) => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Organization updated successfully`,
            },
          });
        })
        .catch((error) => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Update Failed`,
            },
          });
        });
    };

    return {
      // Sidebar
      isLoading,
      onSubmit,
      userData,
      gcpBillingAccountId,
      gcpBillingAccountIdData,
      yourMethod,
      deleteGcpBillingAccountId,
    };
  },
};
</script>
