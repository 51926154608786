import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user")
          // .get(process.env.VUE_APP_USER_MANAGEMENT_SERVICE)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user`, {
            params: {
              page: data.currentPage,
              per_page: data.perPage,
              sort_by: data.sortBy,
              sort_order:
                data.sortOrder
                  ?  "desc"
                  : "asc",
              region_id:data.region,
              orgs: data.orgs,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsersByOrganizationId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/organization/${data.company_id}`, {
            params: {
              page: data.currentPage, per_page: data.perPage,
              sort_by: data.sortBy, sort_order: data.sortOrder ?  'desc' :  'asc'
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsersBySearchQuery(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/organization/${data.company_id}/search`, {
            params: { 
              query: data.searchQuery, per_page: data.perPage, 
              sort_by: data.sortBy, sort_order: data.sortOrder  ? 'desc' :  'asc',
              region_id:data.region, orgs: data.orgs,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganization(ctx, data){
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/organizations/region/${data.region_id}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganizationByAccountManager(ctx, data){
      return new Promise((resolve, reject) => {
        axios
          .get(`/organizations/account_manager/${data.id}`,{
            params: {
              region_id: data.region_id
            },
          }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDomainNames(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/organization/${data.company_id}/domain_names`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/roles/organization/${data.company_id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/roles/organization/${data.company_id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRole(ctx, data){
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/user/roles/organization/${data.company_id}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/user", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${userData.uid}`, userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
