<template>
  <b-card>
    <b-row>
      <b-col cols="12" lg="9">
        <b-form-checkbox-group
          v-model="selected"
          :options="options"
          switches
          style="column-count: 1"
          stacked
        ></b-form-checkbox-group>
      </b-col>
    </b-row>

    <b-button variant="primary" class="mt-1 mr-1" @click="selectedNotification">
      <div class="d-flex align-items-center">
        <b-spinner small label="Text Centered" v-if="isLoading"></b-spinner>
        <span class="button-title">Save changes</span>
      </div>
    </b-button>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import axios from "@axios";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    BFormCheckboxGroup,
  },
  data() {
    return {
      selected: [],
      storeSelected: [],
      options: [],
      isLoading: false,
    };
  },
  beforeMount() {
    this.getNotification();
    this.getSelectedNotification();
  },
  methods: {
    selectedNotification() {
      this.isLoading = true;
      const userData = JSON.parse(localStorage.getItem("userData"));
      let objectData = {};

      let data = this.storeSelected.filter(
        (val) => !this.selected.includes(val)
      );
      for (const element of data) {
        objectData[element] = false;
      }
      for (const element of this.selected) {
        objectData[element] = true;
      }

      const messageObj = JSON.stringify(objectData);

      axios
        .put(`/user/settings/notifications/${userData.id}`, messageObj)
        .then((response) => {
          this.isLoading = false;
          this.selected = [];
          for (const [selectedKey, selectedValue] of Object.entries(
            response.data
          )) {
            if (selectedValue == true) {
              this.selected.push(selectedKey);
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getSelectedNotification() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.selected = [];

      axios
        .get(`/user/settings/notifications/${userData.id}`)
        .then((response) => {
          for (const [selectedKey, selectedValue] of Object.entries(
            response.data
          )) {
            this.storeSelected.push(selectedKey);
            if (selectedValue == true) {
              this.selected.push(selectedKey);
            }
          }
        })
        .catch(() => {});
    },
    getNotification() {
      axios
        .get(`/user/notification/types`)
        .then((response) => {
          for (const [notificationKey, notificationValue] of Object.entries(
            response.data
          )) {
            this.options.push({
              html: `<b>${notificationValue.title}</b><br>${notificationValue.description}</br>`,
              value: notificationValue.name,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
