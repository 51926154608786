<template>
  <b-card>
    <!-- form -->
    <!-- User Info: Input Fields -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="mt-1">
          <!-- Field: First Name -->
          <b-col cols="12" lg="8">
            <validation-provider
              #default="validationContext"
              name="first name"
              rules="required"
            >
              <b-form-group label="First Name" label-for="first-name">
                <b-form-input
                  id="first-name"
                  v-model="userData.first_name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}</b-form-invalid-feedback
                >
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Last Name -->
          <b-col cols="12" lg="8">
            <validation-provider
              #default="validationContext"
              name="last name"
              rules="required"
            >
              <b-form-group label="Last Name" label-for="last-name">
                <b-form-input
                  id="last-name"
                  v-model="userData.last_name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}</b-form-invalid-feedback
                >
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" lg="8">
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="userData.email" readonly />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
          :disabled="userData.first_name == '' || userData.last_name == ''"
        >
          <div class="align-items-center">
            <b-spinner small label="Text Centered" v-if="isLoading"></b-spinner>
            <span class="button-title">Save Changes</span>
          </div>
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click.prevent="resetForm"
        >
          Reset
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormSelect,
  BInputGroup,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import userStoreModule from "../../apps/user/userStoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import router from "@/router";
import ability from "../../../libs/acl/ability";

export default {
  components: {
    BButton,
    BInputGroup,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    BFormInvalidFeedback,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      profileFile: null,
    };
  },
  computed: {
    domainName() {
      return this.userData.emailFirst.includes("@") ? false : true;
    },
  },
  methods: {
    resetForm() {
      this.userData = JSON.parse(localStorage.getItem("userData"));
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    let isLoading = ref(false);
    let domain_names = ref("");
    let domainNames = ref([]);
    let userData = JSON.parse(localStorage.getItem("userData"));

    const toast = useToast();

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const domainData = {
      company_id: userData.company_id,
    };

    store
      .dispatch("app-user/fetchDomainNames", domainData)
      .then((response) => {
        domainNames.value = response.data.name;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching domain names",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    const onSubmit = () => {
      isLoading.value = true;
      userData.uid = userData.id;

      store
        .dispatch("app-user/updateUser", userData)
        .then(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `User updated successfully`,
            },
          });
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
          localStorage.removeItem("userData");
          localStorage.removeItem("regionCurrencyData");
          localStorage.removeItem("notificationCount");
          localStorage.removeItem("notificationData");
          localStorage.removeItem("securityanalysisData");
          localStorage.removeItem("securityanalysisDatabaseNameIndex");
          ability.update(initialAbility);
          router.push({ name: "auth-login" });
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Update Failed`,
            },
          });
        });
    };

    const { refFormObserver, getValidationState } = formValidation();

    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      isLoading,
      onSubmit,
      userData,
      getValidationState,
      refFormObserver,
      domain_names,
      domainNames,
    };
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.btn-secondary {
  background-color: $white !important;
  color: $black !important;
  border-color: $black !important;
}
</style>
