<template>
  <b-row class="d-flex justify-content-center">
    <b-col cols="12" lg="10">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <!-- general tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">General</span>
          </template>

          <account-setting-general />
        </b-tab>
        <!--/ general tab -->

        <!-- change password tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon icon="LockIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Change Password</span>
          </template>

          <account-setting-password />
        </b-tab>
        <!--/ change password tab -->

        <!-- info -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon icon="BriefcaseIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Company Details</span>
          </template>

          <account-company-details />
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon icon="BellIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Notification settings</span>
          </template>

          <account-notification-settings />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountCompanyDetails from "./AccountCompanyDetails.vue";
import AccountNotificationSettings from "./AccountNotificationSettings.vue";

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountCompanyDetails,
    AccountNotificationSettings,
  },
};
</script>
