import axios from "@axios";

export default {
  namespaced: true,
  state: {
    organizations: [],
  },
  getters: {
    organizationsList(state) {
      return state.organizations;
    },
  },
  mutations: {
    SET_ORGANIZATIONS(state, val) {
      state.organizations = val;
    },
  },
  actions: {
    addOrganization(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/organization", data.organizationData, {
            params: {
              cloud_channel_addition: data.cloudChannelAddition
            }
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganizations(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/organization", {
            params: {
              page: data.currentPage,
              per_page: data.perPage,
              sort_by: data.sortBy,
              sort_order:
                data.sortOrder
                  ? "desc"
                  : "asc",
              region_id: data.region,
              currency_id: data.currency,
              search: data.searchQuery
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganizationsByAccountManager(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/organizations/account_manager/${data.accountManagerId}`, {
            params: {
              page: data.currentPage,
              per_page: data.perPage,
              sort_by: data.sortBy,
              sort_order:
                data.sortOrder
                  ? "desc"
                  : "asc",
              region_id: data.region,
              currency_id: data.currency,
              search: data.searchQuery
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSearch(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/organization/search", {
            params: {
              query: data.searchQuery,
              per_page: data.perPage,
              sort_by: data.sortBy,
              sort_order:
                data.sortOrder
                  ? "desc"
                  : "asc",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganization(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/organization/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCurrencyTypes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/currency`, {
            params: {
              page: 0,
              per_page: 10,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRegionTypes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/region`, {
            params: {
              page: 0,
              per_page: 10,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCountries(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/region/countries`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLanguageCodes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/region/languages`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAccountManager(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/roles/5/users`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSupportTiers(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/organizations/support_tier`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRoles(ctx, data){
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/user/roles/organization/${data.company_id}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateOrganization(ctx, organizationData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/organization/${organizationData.id}`, organizationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getDomainName(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/organization/${id}/domain_names`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addDomainName(ctx, domainData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/organization/${domainData.id}/domain_names`, {
            name: domainData.name,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteDomainName(ctx, domainData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/organization/${domainData.id}/domain_names`, {
            data: { name: [domainData.name] },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteOrganization(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/organization/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
