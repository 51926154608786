<template>
  <b-card>
    <!-- form -->
    <validation-observer #default="{ invalid }" ref="refFormObserver">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <!-- new password -->
          <b-col lg="8">
            <validation-provider #default="{ errors }" name="New Password" rules="required|min:6" vid="password">
              <b-form-group label-for="account-new-password" label="New Password">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew"
                    name="new-password" placeholder="New Password" :state="errors.length > 0 ? false : null"
                    autocomplete="off" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col lg="8">
            <validation-provider #default="{ errors }" name="Retype New Password" rules="required|confirmed:password">
              <b-form-group label-for="account-retype-new-password" label="Retype New Password">
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-retype-new-password" v-model="RetypePassword"
                    :type="passwordFieldTypeRetype" name="retype-password" placeholder="New Password"
                    :state="errors.length > 0 ? false : null" autocomplete="off" />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer"
                      @click="togglePasswordRetype" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit"
              :disabled="invalid">
              <div class="d-flex align-items-center">
                <b-spinner small label="Text Centered" v-if="isLoading"></b-spinner>
                <span class="button-title">Save Changes</span>
              </div>
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1">
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, confirmed } from "@validations";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      required,
      min,
      confirmed,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    onSubmit() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.isLoading = true;
      this.$refs.refFormObserver.validate().then((success) => {
        const passwordData = JSON.stringify({
          new_password: this.newPasswordValue,
        });
        axios
          .post("/auth/password/change", passwordData)
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success `,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Change password successfully`,
              },
            });
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem("userData");
            localStorage.removeItem("regionCurrencyData");
            localStorage.removeItem("notificationCount");
            localStorage.removeItem("notificationData");
            localStorage.removeItem("securityanalysisData");
            localStorage.removeItem("securityanalysisDatabaseNameIndex");
            this.$ability.update(initialAbility);
            this.$router.push({ name: "auth-login" });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: `can not update password`,
              },
            });
          });
      });
    },
  },
};
</script>
